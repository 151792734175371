import MapboxClient from '@mapbox/mapbox-sdk';
import MapboxGeocoder from '@mapbox/mapbox-sdk/services/geocoding';

// https://github.com/mapbox/mapbox-sdk-js/blob/master/docs/services.md#geocoding
// https://docs.mapbox.com/api/search/geocoding/

function geocoder() {
    return MapboxGeocoder(MapboxClient({ accessToken: CultureDays.mapboxToken }));
}

export async function forwardGeocode(query, options = {}) {
    const response = await geocoder()
        .forwardGeocode({
            query,
            countries: ['ca'],
            proximity: 'ip',
            language: [CultureDays.locale],
            ...options,
        })
        .send();
    return response.body.features.map(({ id, text, geometry, place_name, place_type, properties, context }) => {
        return {
            id,
            text,
            place_name,
            geo: { lng: geometry.coordinates[0], lat: geometry.coordinates[1] },
            address: place_type.includes('poi') ? properties?.address : null,
            place: context.find(({ id }) => id.startsWith('place'))?.text,
            city: context.find(({ id }) => id.startsWith('district'))?.text,
            province: context.find(({ id }) => id.startsWith('region'))?.text,
        };
    });
}

export async function reverseGeocode(coordinates, options = {}) {
    let response = await geocoder()
        .reverseGeocode({
            query: coordinates,
            countries: ['ca'],
            language: [CultureDays.locale],
            ...options,
        })
        .send();
    return response?.body?.features?.[0];
}

let canada = {
    AB: {
        center: { lat: 54.5, lng: -115 },
        bounds: [
            { lat: 48.8, lng: -120.2 },
            { lat: 60.1, lng: -109.7 },
        ],
    },
    BC: {
        center: { lat: 54.1, lng: -126.4 },
        bounds: [
            { lat: 48, lng: -139.2 },
            { lat: 60.1, lng: -113.7 },
        ],
    },
    MB: {
        center: { lat: 54.5, lng: -95.4 },
        bounds: [
            { lat: 48.8, lng: -102.3 },
            { lat: 60.1, lng: -88.5 },
        ],
    },
    NB: {
        center: { lat: 46.5, lng: -66.4 },
        bounds: [
            { lat: 44.9, lng: -69.2 },
            { lat: 48.1, lng: -63.6 },
        ],
    },
    NL: {
        center: { lat: 53.6, lng: -60.3 },
        bounds: [
            { lat: 46.5, lng: -68.1 },
            { lat: 60.6, lng: -52.4 },
        ],
    },
    NS: {
        center: { lat: 45.3, lng: -63 },
        bounds: [
            { lat: 43.3, lng: -66.5 },
            { lat: 47.2, lng: -59.4 },
        ],
    },
    NT: {
        center: { lat: 67.3, lng: -119.2 },
        bounds: [
            { lat: 59.9, lng: -136.7 },
            { lat: 74.7, lng: -101.7 },
        ],
    },
    NU: {
        center: { lat: 67.5, lng: -91 },
        bounds: [
            { lat: 59.9, lng: -121.4 },
            { lat: 75, lng: -60.7 },
        ],
    },
    ON: {
        center: { lat: 49.4, lng: -84.8 },
        bounds: [
            { lat: 41.8, lng: -95.5 },
            { lat: 57.1, lng: -74.1 },
        ],
    },
    PE: {
        center: { lat: 46.5, lng: -63.2 },
        bounds: [
            { lat: 45.8, lng: -64.6 },
            { lat: 47.1, lng: -61.9 },
        ],
    },
    QC: {
        center: { lat: 53.8, lng: -68.4 },
        bounds: [
            { lat: 44.9, lng: -78 },
            { lat: 62.8, lng: -56.9 },
        ],
    },
    SK: {
        center: { lat: 54.5, lng: -105.7 },
        bounds: [
            { lat: 48.8, lng: -110.3 },
            { lat: 60.1, lng: -101.1 },
        ],
    },
    YT: {
        center: { lat: 65.2, lng: -132.4 },
        bounds: [
            { lat: 59.7, lng: -141.6 },
            { lat: 70.7, lng: -123.2 },
        ],
    },
    center: { lat: 62.4, lng: -96.5 },
    bounds: [
        { lat: 42.1, lng: -139.85 },
        { lat: 74.7, lng: -52.66 },
    ],
};

export function center(province) {
    if (!province) {
        return canada.center;
    } else if (typeof province === 'number') {
        return Object.values(canada)[province - 1]?.center;
    } else {
        return canada[province.toUpperCase()]?.center;
    }
}

export function bounds(province) {
    if (!province) {
        return canada.bounds;
    } else if (typeof province === 'number') {
        return Object.values(canada)[province - 1]?.bounds;
    } else {
        return canada[province.toUpperCase()]?.bounds;
    }
}
